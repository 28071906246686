import axios from '@/utils/api.request'
export const pay = (data) => {
    return axios.request({
      url: '/unionPay/getPayOrderUrl',
      method: 'post',
      data
    })
  }
  export const getPayResult = (data) => {
    return axios.request({
      url: '/unionPay/queryPayResult',
      method: 'post',
      data
    })
  }
  export const Refund = (data) => {
    return axios.request({
      url: '/unionPay/refund',
      method: 'post',
      data
    })
  }