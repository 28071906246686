<template>
  <div>
    <div class="nameBox">
      <div class="name">{{ infos.meetTitle }}</div>
      <div class="time">
        {{ meetDate }}
      </div>
      <div class="signup">
        <div class="money">
          <span>报名费：</span>
          <span class="red">{{ infos.payAmount }} 元</span>
        </div>
      </div>
      <div class="addBox">
        <span>剩余名额：{{ infos.subUser }} 人</span>
        <Button
          class="btn"
          v-if="ifSign"
          icon="add-o"
          @click="goEditPage"
          color="#2B72FF"
          size="small"
          >添加培训人员</Button
        >
        <Button class="btn" v-else icon="add-o" color="#7f7f7f" size="small"
          >添加培训人员</Button
        >
      </div>
    </div>
    <div class="infoBox">
      <AttendeeList
        :list="attenderlist"
        :meetId="id"
        :meetStatus="infos.meetStatus"
        :applyField="infos.applyField"
        @updateList="updateList"
      />
    </div>
    <div class="btnBox">
      <Button
        class="radius-5"
        v-if="attenderlist.length > 0 && ifSign"
        color="#2B72FF"
        block
        :loading='loading' loading-text='提交中'
        @click="pay"
        >报名缴费</Button
      >
      <Button class="radius-5" v-else color="#7f7f7f" block >报名缴费</Button>
    </div>
  </div>
</template>

<script>
import MeetTitle from "./component/meetTitle.vue";
import AttendeeList from "./component/attendeeList.vue";
import { Button, Dialog } from "vant";
import { getMeetInfo, getMeetOrder, getAttenderList,generateMeetOrderZero } from "@/api/meet.js";
import { pay } from "@/api/pay.js";

var u = navigator.userAgent;
var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

export default {
  name: "signUp",
  components: {
    MeetTitle,
    AttendeeList,
    Button,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      id: "",
      infos: {}, //培训详情
      orderInfo: {}, //培训待付款订单
      attenderlist: [], //参会人员列表
      ifSign: false, //已取消的和未开始报名的，按钮置灰
      loading:false
    };
  },
  computed: {
    meetDate() {
      if (this.infos.meetTimeStart) {
        let str =
          this.infos.meetTimeStart.split("-").join("/") +
          "-" +
          this.infos.meetTimeEnd.split("-").join("/");
        return str;
      } else {
        return "";
      }
    },
  },
  created() {
    if(this.$route.params.id){
      let id = this.$route.params.id;
      this.id = id;
      this.getDeatil(id);
    }
    if(this.$route.query.meetId){
      this.id=this.$route.query.meetId
      this.getDeatil(this.$route.query.meetId);
    }
    if(!this.$route.query.meetId&&!this.$route.params.id){
      this.$router.replace('/meetList');
    }

  },
  methods: {
    //获取培训详情
    getDeatil(id) {
      getMeetInfo({ id }).then((res) => {
        if (res.status == "S") {
          this.infos = res.data;
          if (res.data.meetStatus > 1 || res.data.meetStatus==0) {
            this.ifSign = false;
          } else {
            this.ifSign = true;
          }
          this.getMeetOrder(id);
        }
      });
    },
    //获取培训相关订单
    getMeetOrder(id) {
      let clientId = localStorage.getItem("clientId");
      getMeetOrder({ meetId: id, clientId }).then((res) => {
        if (res.status == "S") {
          if (res.data) {
            this.ifSign = true;
            this.orderInfo = res.data;
            this.getAttenderList(res.data.id);
          }
        }
      });
    },
    //获取参会人列表
    getAttenderList(orderId) {
      getAttenderList({ orderId }).then((res) => {
        if (res.status == "S") {
          this.attenderlist = [...res.data];
        }
      });
    },
    //更新参会人列表
    updateList() {
      this.getDeatil(this.id)
     this.getAttenderList(this.orderInfo.id);
    },
    goEditPage() {
      this.$router.push({
        name: "editAttendee",
        params: {
          info: { id: "", orderId: this.orderInfo.id },
          applyField: this.infos.applyField,
          meetId: this.id,
          meetStatus:this.infos.meetStatus
        },
      });
    },
    pay() {
      // this.$router.push("/signSuccess");
      if (this.attenderlist.length > 0) {
        let totalAmount = this.infos.payAmount * this.attenderlist.length;
        this.loading=true
        //是否是0元订单
        if(totalAmount>0){
          pay({ merOrderId: this.orderInfo.id, totalAmount,orderType:'0' }).then((res) => {
            if (res.status == "S") {
              let url = res.data;
              if (isAndroid) {
                //android终端
                window.open(url);
              } else if (isiOS) {
                //ios终端
                window.location.href = url;
              }
            }else{
              this.loading=false
            }
          }).catch(res=>{
            this.loading=false
          })
        }else{
          generateMeetOrderZero({orderId:this.orderInfo.id}).then(res=>{
            this.loading=false;
            if(res.status=="S"){
              this.$router.push(`/paySuccess?orderId=${this.orderInfo.id}&money=0&countNum=${this.attenderlist.length}`)
            }
          }).catch(res=>{
            this.loading=false
          })
        }
      }
    },
  },
  beforeDestroy(){
     this.loading=false
  }
};
</script>

<style lang="less" scoped>
.nameBox {
  height: 2.55rem;
  background: url("../../assets/detailBg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.35rem 0.25rem 0;
  box-sizing: border-box;
  div {
    font-size: 14px;
    color: #fff;
  }
  .name {
    font-weight: bold;
  }
  .time {
    font-weight: normal;
    color: #dfdfdf;
    margin-top: 0.15rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    span {
      color: #f62f2f;
    }
  }

  .signup {
    margin-top: 0.15rem;
    .money {
      color: #dfdfdf;
      font-weight: normal;
      .red {
        color: #f62f2f;
        font-weight: bold;
      }
    }
    .num {
      margin-left: 0.25rem;
    }
  }
  .addBox {
    width: 100%;
    margin-top: 0.12rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #dfdfdf;
    font-weight: normal;
    .btn {
      width: 130px;
      height: 0.29rem;
      border-radius: 5px;
      font-weight: normal;
    }
  }
}
.infoBox {
  margin-top: -0.2rem;
  background: #fff;
  border-radius: 20px 20px 0px 0px;
  padding: 0.2rem 0.12rem 0.4rem;
  height: 0.5rem;
}
.btnBox {
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: #fff;
  padding: 0.12rem 0.15rem;
  .radius-5 {
    border-radius: 5px;
    height: 0.42rem;
  }
}
</style>
